define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/08d5b4341794ee54be157a07d02c6594b80f55e9/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/08d5b4341794ee54be157a07d02c6594b80f55e9/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/home/mcdeploy/mc_node_modules_cache/08d5b4341794ee54be157a07d02c6594b80f55e9/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-geometry!/home/mcdeploy/mc_node_modules_cache/08d5b4341794ee54be157a07d02c6594b80f55e9/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/home/mcdeploy/mc_node_modules_cache/08d5b4341794ee54be157a07d02c6594b80f55e9/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/home/mcdeploy/mc_node_modules_cache/08d5b4341794ee54be157a07d02c6594b80f55e9/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/html", "dojo/promise/all", "dojo-proxy-loader?name=dojo/Deferred!/home/mcdeploy/mc_node_modules_cache/08d5b4341794ee54be157a07d02c6594b80f55e9/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/sniff!/home/mcdeploy/mc_node_modules_cache/08d5b4341794ee54be157a07d02c6594b80f55e9/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/text!./templates/banner.html", "mojo/widgets/_CustomStyleRulesMixin"], function (declare, _WidgetBase, _TemplatedMixin, lang, on, domGeom, domConstruct, domStyle, html, all, Deferred, has, tplForm, _CustomStyleRulesMixin) {
  var modalBanner = declare([_WidgetBase, _TemplatedMixin], {
    templateString: tplForm,
    config: {},
    description: "",
    // Description text from CK editor

    subscribeLabel: "Subscribe",
    // Default Button label

    bannerDescription: "",
    bannerCtaLabel: "Subscribe",
    closeLabel: "Close",
    appDomain: "",
    postMixInProperties: function () {
      // Set attributes from the config
      if (this.config.closeLabel) {
        this.closeLabel = this.config.closeLabel;
      }
      if (this.config.description) {
        this.description = this.config.description;
      }
      if (this.config.buttonLabel) {
        this.subscribeLabel = this.config.buttonLabel;
      }
      if (this.config.bannerDescription) {
        this.bannerDescription = this.config.bannerDescription;
      } else if (this.config.description) {
        this.bannerDescription = this.config.description;
      }
      if (this.config.bannerCtaLabel) {
        this.bannerCtaLabel = this.config.bannerCtaLabel;
      } else if (this.config.buttonLabel) {
        this.bannerCtaLabel = this.config.buttonLabel;
      }
    },
    postCreate: function () {
      this.inherited(arguments);
    }
  });
  return declare([_WidgetBase, _CustomStyleRulesMixin], {
    version: "1.0",
    iframe: null,
    frameDoc: null,
    // Config that confines to what the server model generates
    config: {},
    customCssNode: null,
    // custom form styles from editor

    env: "prod",
    // Swaps location of resources

    constructor: function (props) {
      if (!props.iframe || !props.iframe.tagName || props.iframe.tagName.toLowerCase() !== "iframe") {
        throw new Error("You must specify an iframe attribute to an iframe element");
      }
    },
    postMixInProperties: function () {
      // Pull in any attributes from the config
      if (this.config.version) {
        this.version = this.config.version;
      }
      if (this.config.styles) {
        this.styles = this.config.styles;
      }
      this.frameDoc = this.iframe.contentWindow.document;
    },
    postCreate: function () {
      this.bannerContent = new modalBanner({
        config: this.config
      });
      this.frameDoc.write('<!DOCTYPE html><html><head><meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"/></head><body></body></html>');
      this.frameDoc.close();
      this.bannerContent.placeAt(this.frameDoc.body);
      html.set(this.bannerContent.bannerDescriptionContainer, this.config.bannerDescription);

      // Update the height when CSS is finished loading
      all([this.loadCommonCss(), this.loadBannerCss(), this.loadCustomCss()]).then(lang.hitch(this, function () {
        this.defer(this.updateDocHeight, 500);
      }));
      on(window, "resize", lang.hitch(this, function () {
        this.updateDocHeight();
      }));
    },
    startup: function () {
      this.inherited(arguments);
    },
    docHeight: function () {
      return domGeom.getMarginBox(this.bannerContent.bannerContainer).h;
    },
    updateDocHeight: function () {
      // FF throws an error when you try to get the height of a hidden element
      // See: https://bugs.dojotoolkit.org/ticket/12987)
      try {
        domStyle.set(this.iframe, "height", this.docHeight() + "px");
      } catch (e) {}
    },
    loadCommonCss: function () {
      var commonDeferred = new Deferred();
      var common = this.frameDoc.createElement("link");
      common.rel = "stylesheet";
      common.type = "text/css";
      if (this.env === "dev") {
        var pathPrefix = this.appDomain === "" ? "" : "//" + this.appDomain;
        common.href = pathPrefix + "/css/signup-forms/popup/common.css";
      } else {
        common.href = "//downloads.mailchimp.com/css/signup-forms/popup/" + this.version + "/common.css";
      }
      common.media = "all";
      on(common, "load", function () {
        commonDeferred.resolve();
      });
      this.frameDoc.getElementsByTagName("head")[0].appendChild(common);
      return commonDeferred.promise;
    },
    loadBannerCss: function () {
      var bannerDeferred = new Deferred();
      var banner = document.createElement("link");
      banner.rel = "stylesheet";
      banner.type = "text/css";
      if (this.env === "dev") {
        var pathPrefix = this.appDomain === "" ? "" : "//" + this.appDomain;
        banner.href = pathPrefix + "/css/signup-forms/popup/banner.css";
      } else {
        banner.href = "//downloads.mailchimp.com/css/signup-forms/popup/" + this.version + "/banner.css";
      }
      banner.media = "all";
      on(banner, "load", function () {
        bannerDeferred.resolve();
      });
      this.frameDoc.getElementsByTagName("head")[0].appendChild(banner);
      return bannerDeferred.promise;
    },
    loadCustomCss: function () {
      if (this.customCssNode) {
        domConstruct.destroy(this.customCssNode);
      }
      var customDeferred = new Deferred();
      this.customCssNode = this.createStyleNode(this.frameDoc);
      var sheet = this.getStyleSheet(this.customCssNode);
      if (this.styles) {
        for (var el in this.styles) {
          if (this.styles.hasOwnProperty(el)) {
            switch (el) {
              case "bannerCta":
                // eslint-disable-next-line guard-for-in
                for (var prop in this.styles[el]) {
                  switch (prop) {
                    case "color":
                      this.addCSSRule(sheet, ".bannerContent__subscribeButton", "background-color:" + this.styles[el][prop] + ";");
                      break;
                    case "hover_color":
                      this.addCSSRule(sheet, ".bannerContent__subscribeButton:hover", "background-color:" + this.styles[el][prop] + ";");
                      break;
                    case "text_color":
                      this.addCSSRule(sheet, ".bannerContent__subscribeButton", "color:" + this.styles[el][prop] + ";");
                      break;
                    case "alignment":
                      if (this.styles[el][prop] === "right") {
                        this.addCSSRule(sheet, ".bannerContent__subscribeButton", "float:right;");
                      } else if (this.styles[el][prop] === "center") {
                        this.addCSSRule(sheet, ".bannerContent__subscribeButton", "float:none;margin-left:auto;margin-right:auto;");
                        if (has("ie") <= 9) {
                          this.addCSSRule(sheet, ".bannerContent__subscribeButton", "display:table-cell;");
                          this.addCSSRule(sheet, ".bannerContent__buttonContainer", "margin-left:auto;margin-right:auto;display:table;");
                        } else {
                          this.addCSSRule(sheet, ".bannerContent__subscribeButton", "display:table;");
                        }
                      } else {
                        this.addCSSRule(sheet, ".bannerContent__subscribeButton", "float:left;");
                      }
                      break;
                    case "style":
                      if (this.styles[el][prop] === "full") {
                        this.addCSSRule(sheet, ".bannerContent__subscribeButton", "width:100%;");
                      }
                      break;
                    default:
                      break;
                  }
                }
                break;
              case "backgroundColor":
                domStyle.set(this.bannerContent.bannerContainer, "background-color", this.styles[el]);
                break;
              case "paragraphColor":
                this.addCSSRule(sheet, ".bannerContent__closeButton::before, .bannerContent__closeButton::after", "background-color:" + this.styles[el] + ";");
                break;
              default:
                break;
            }
          }
        }
      }
      customDeferred.resolve();
      return customDeferred.promise;
    }
  });
});
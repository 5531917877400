define(["dojo-proxy-loader?name=dojo/_base/declare!/home/mcdeploy/mc_node_modules_cache/08d5b4341794ee54be157a07d02c6594b80f55e9/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/page/request"], function (declare, request) {
  return declare([], {
    constructor: function (params) {
      document.addEventListener('click', function (e) {
        if (e.target.tagName !== 'A') {
          return;
        }
        request.get(params.endpoint + "&url=" + encodeURIComponent(e.target.href));
      }, false);
    }
  });
});
/**
 * Contains any app-wide attribute we expose that's specific to the current user.
 * If there's no user, you will get the defaults which are listed in this module.
 *
 * To expose any information that's not related to a user, please add it to mojo/context.
 *
 * see _js_settings.html for how these values are overriden
 */
define(["dojo-proxy-loader?name=dojo/_base/xhr!/home/mcdeploy/mc_node_modules_cache/08d5b4341794ee54be157a07d02c6594b80f55e9/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/08d5b4341794ee54be157a07d02c6594b80f55e9/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/08d5b4341794ee54be157a07d02c6594b80f55e9/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/when!/home/mcdeploy/mc_node_modules_cache/08d5b4341794ee54be157a07d02c6594b80f55e9/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/url", "mojo/lib/logger", "mojo/lib/request-helpers", "dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/08d5b4341794ee54be157a07d02c6594b80f55e9/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/NodeList-manipulate", "mojo/context"], function (xhr, array, lang, when, url, mLogger, requestHelpers, query, nodeListManipulate, context) {
  var roles = ["owner", "admin", "manager", "author", "viewer"];
  var User = window.User = {
    __csrfToken: null,
    role: null,
    policy: null,
    type: null,
    settings: {
      pageSize: 25,
      fileManagerShowGrid: false
    },
    avatarUrl: null,
    pro: false,
    userId: null,
    loginId: null,
    userLevel: null,
    isAnyEcomm: null,
    hasAdvancedSegmentation: false,
    hasPaid: false,
    currencySymbol: "$",
    currencyCode: "USD",
    currencyLocale: "en-US",
    displayLocale: "en",
    timezoneId: null,
    timezoneLabel: null,
    timezoneOffset: null,
    mustTrackLinks: false,
    isGoalAvailable: false,
    isGoalEnabled: false,
    canSend: false,
    freeLimit: 0,
    isPayAsYouGo: false,
    brandLogoUrl: null,
    isND: false,
    websiteLogoUrl: null,
    canSeePaywall: true,
    hasLegacyPaidPlan: false,
    hasPremiumMonthlyPlan: false,
    hasStandardMonthlyPlan: false,
    hasProducts: false,
    canAccessContentGen: false,
    isFree: null,
    /**
     * Save a user setting
     *
     * @param {string} setting - The key for the setting
     * @param {*} value - the value to save
     */
    saveSetting: function (setting, value) {
      if (this.userId) {
        xhr.post({
          url: url.toUrl("/app-homepage/save-user-setting"),
          content: {
            "key": setting,
            "value": value
          }
        });
      }
    },
    /**
     * Save a user setting for the active session
     *
     * @param {string} setting - the key for the setting
     * @param {*} value - the value to save
     */
    saveSessionSetting: function (setting, value) {
      xhr.post({
        url: url.toUrl("/app-homepage/save-session-setting"),
        content: {
          "key": setting,
          "value": value
        }
      });
    },
    /**
     * Check whether a user has the appropriate role
     *
     * @param {string} role - Role user must be as powerful or more powerful than
     *
     * @returns {boolean} True iff user has role at or above given role
     */
    hasRole: function (role) {
      return array.indexOf(roles, role) >= array.indexOf(roles, User.role);
    },
    /**
     * Update token and any existing forms in the page.
     *
     * @param {string} token - New csrf token
     */
    updateCSRF: function (token) {
      var old = User.__csrfToken;
      query("[name=__csrf_token][value=" + old + "]").val(token);
      User.__csrfToken = token;
    },
    /**
     * Get the value of the CSRF token that should be passed along to the backend.
     *
     * @returns {String} - Token provided by the backend
     */
    getCsrfToken: function () {
      if (this.__csrfToken) {
        return this.__csrfToken;
      }

      // csrf token is not being hydrated within the context in which this widget is being called.
      // In most situations this value is hydrated by _js_settings.html.
      throw new Error("Error retrieving __csrfToken");
    },
    getUserLogins: function () {
      if (this._userLogins) {
        return when(this._userLogins);
      }
      return xhr.get({
        url: url.toUrl("/account/apis/get-user-logins"),
        handleAs: "json"
      }).then(function (response) {
        this._userLogins = response;
        return this._userLogins;
      }.bind(this), requestHelpers.throwError);
    },
    /**
     * Check whether a user has a particular flag
     *
     * @param {string} flag - Name of flag
     *
     * @returns {boolean} True iff user has flag
     */
    flagIsOn: function (flag) {
      // [FP-682]: Changing the `user.flagIsOn` to match with `context.flagIsOn` so webpack can load flag context correctly.
      return context.flagIsOn(flag);
    },
    getBootstrappedInstance: function () {
      if (this.isBootstrapped) {
        return this;
      }
      return window.top.User;
    },
    /**
     * Format a currency in a user's locale.
     *
     * @param {number} number the amount to format
     * @param {string} [currencyCode] the 3-digit ISO code of the desired currency. If not provided, the amount will
     * be formatted in the user's default currency
     * @returns {string} a formatted monetary value
     */
    formatCurrency: function (number, currencyCode) {
      currencyCode = currencyCode || this.currencyCode;
      return number.toLocaleString(this.currencyLocale, {
        style: "currency",
        currency: currencyCode
      });
    },
    // TODO: hook this to real ajax
    refresh: function () {
      return xhr.get({
        url: url.toUrl("/account/users/get-info"),
        handleAs: "json"
      }).then(function (result) {
        window.User = lang.mixin(window.User, result);
        return window.User;
      });
    }
  };
  return User;
});